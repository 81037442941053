import React from 'react'
import { Layout } from '@raylink-overseas/common/components'
import TransferBanner from '../components/FeatureBanner'
import ListFlow from '../components/ListFlow'
import StepSlide from '../components/StepSlide'
import Question from '../components/Question'
import StartNow from '../components/StartNow'

import TransferMain from '../images/multiple-main.png'
import MultiMonitorMainMobile from '../images/MultiMonitorBgMobile.png'
import One2Multi from '../images/one2multi.png'
import Multi2Multi from '../images/multi2multi.png'
import CrossPlatformRC from '../images/cross-platform-rc.png'
import DownloadInstall from '../images/DownloadInstall.png'
import AccessControl from '../images/AccessControl.png'
import ControlMultiScreens from '../images/ControlMultiScreens.png'
import MobileLogin from '../images/MobileLogin.png'
import MobileConnect from '../images/MobileConnect.png'
import MobileView from '../images/MobileView.png'

const descList = [
  'Support one-to-multi and multi-to-multi remote access.',
  'Access multi-screen computers from desktop or mobile devices.',
  'Effortlessly switch and drag different remote screen tabs.',
]

const flowItemList = [
  {
    img: One2Multi,
    img_mobile: One2Multi,
    title: 'One-to-Multi Monitor',
    desc: 'Avica allows remote access to multi-monitor devices, even when the local device only has a single screen. Upon establishing a remote connection, the local device will display individual tabs for each screen of the remote multi-monitor device. These tabs can be easily separated or combined, and also support file sharing between them.',
    ifRotation: false,
    ifShowDownloadBtn: false,
  },
  {
    img: Multi2Multi,
    img_mobile: Multi2Multi,
    title: 'Multi-to-Multi Monitor',
    desc: 'With Avica, the local computer that has multiple screens can easily control multi-monitor devices remotely. Once connected, the local device will seamlessly display multiple screens from the remote device. Additionally, users can conveniently drag and drop the screens from the remote session onto any physical screens of the local device, enabling a smooth viewing and control experience. This feature enhances productivity by facilitating efficient multitasking.',
    ifRotation: true,
    ifShowDownloadBtn: false,
  },
  {
    img: CrossPlatformRC,
    img_mobile: CrossPlatformRC,
    title: 'Cross-Platform Remote Control',
    desc: 'With Avica, users can control and view the Windows or Mac multi-monitor computer from various platforms including Windows, Mac, iOS, and Android devices. This means that regardless of the operating system or device they are using, users can remotely access and manage their multi-screen setup with ease.',
    ifRotation: false,
    ifShowDownloadBtn: false,
  },
]

const tabInfoList = [
  {
    tabName: 'Connect from Desktop Device',
    ifShowSelectBox: true,
    isPlay: true,
  },
  {
    tabName: 'Connect from Mobile Device ',
    ifShowSelectBox: true,
    isPlay: true,
  },
]

const tab1List = [
  {
    img: DownloadInstall,
    title: 'Step 1. Download and Install',
    desc: 'Download and install the Avica app on local and remote computers. Launch Avica and sign in.',
    redirect: false,
  },
  {
    img: AccessControl,
    title: 'Step 2. Initiate a Remote Session',
    desc: 'Enter the remote device\'s Avica ID on the local computer and click the "Connect" button. Then, you can select Password-Free Connection or enter the Password to start a remote connection.',
    redirect: false,
  },
  {
    img: ControlMultiScreens,
    title: 'Step 3. Control Multiple Screens',
    desc: "On the local device's screen, you will see multiple tabs representing the remote device's monitors. You can freely switch, split, or merge these tabs or drag the tab onto your other local physical displays.",
    redirect: false,
  },
]

const tab2List = [
  {
    img: MobileLogin,
    title: 'Step 1. Install and Login',
    desc: 'Install the Avica mobile app on your phone and also install Avica on your remote computer. Launch it and sign in.',
    redirect: false,
  },
  {
    img: MobileConnect,
    title: 'Step 2. Initiate a Remote Session',
    desc: 'Enter the remote computer\'s Avica ID on the local mobile device and click the "Connect" button. Then, you can use Password-Free Connection or enter the Password to start a remote session.',
    redirect: false,
  },
  {
    img: MobileView,
    title: 'Step 3. View and Control Multi-Screen',
    desc: "Once connected, you can use the Switch Screen feature in the toolbar to switch between different remote device's screens on your phone.",
    redirect: false,
  },
]

const More = [tab1List, tab2List]

const faqList = [
  {
    question:
      'How many remote monitors (tabs) can be displayed on Avica simultaneously?',
    answer:
      'Avica can display up to five screens (tabs) of a remote device simultaneously.',
  },
  {
    question: 'What are the advantages of using multi-monitor remote desktop?',
    answer:
      'Using remote desktop with multiple monitors allows for efficient multitasking, access to the work environment from anywhere, seamless transition between screens, better visualization of work or data, and easy transfer of files or content between monitors.',
  },
  {
    question: 'Can Windows 10 support multiple RDP sessions?',
    answer:
      'No, the built-in Remote Desktop feature in Windows 10 only allows for a single concurrent session. However, you can use a third-party tool to achieve multiple RDP sessions and Avica is one of the most recommended options. ',
  },
]

const MultiMonitor = () => {
  return (
    <Layout pageType="multiMonitor">
      <TransferBanner
        title="Remote Desktop to Multiple Monitors"
        descList={descList}
        photo={TransferMain}
        mobile={MultiMonitorMainMobile}
      />
      <ListFlow
        featureList={flowItemList}
        title="View and Control Multiple Monitors with Remote Desktop"
        className="no-desc-title"
      />
      <StepSlide
        title="How to Remote Desktop on Multiple Screens with Avica?"
        ifShowTab
        More={More}
        TabInfo={tabInfoList}
        className_title="has-tab-title"
      />
      <Question faqList={faqList} />
      <StartNow
        pre="Start to Remote Desktop to"
        span="Multiple Monitors"
        suf="Now"
      />
    </Layout>
  )
}

export default MultiMonitor
